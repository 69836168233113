import React from 'react'
import FAQs from '../../camponets/FAQs/FAQs';
import TechnologiesBanner from '../TechnologiesBanner/TechnologiesBanner';
import TechnologiesTitleSubtitle from '../TechnologiesTitleSubtitle/TechnologiesTitleSubtitle';
import { Link } from 'react-router-dom';
import './TechnologiesDetailsNew.css'
import Processstep from '../../camponets/TechnologyHireCard/TechnologyHireCard';
import DevelopmentSkillCard from '../Developmentskillcard/Developmentskillcard';
import MySwiperComponent from '../TechnologiesTestimonials/TechnologiesTestimonials';

export default function TechnologiesDetailsNew({ TechName, AboutCardData, BannerData, TechDetailsCardData, TechFancyCardData, DevelopmentSkillCardData, KeyBenefitsCardData, TestimonialsData }) {

    return (
        <>
            {BannerData.map((item, ind) => {
                return (
                    <TechnologiesBanner
                        key={ind}
                        image={item.BannerimgUrl}
                        IsButton={true}
                        isHire={true}
                        title={item.htmlString}
                        description={item.BennerDecription}
                        alt={item.alt}
                    />
                )
            })}
            <div className='page-content container'>
                <TechnologiesTitleSubtitle title='REACT NATIVE DEVELOPMENT' subtitle={`Hire A Skilled React Native Development Team`} />
                <p className='simple-p-tag' style={{color : 'black'}}>
                    Trying to find the best and right React talent? Get access to the best React Native developers at Technolee. With exceptional talent, creative, and proficiency, build holistic cross-platform application
                </p>
                <div className='btn-arr'>
                    <div className='Getstarted-btn' >
                        <Link to="/contactus"> <span>GET STARTED NOW</span></Link>
                    </div>
                </div>
            </div>

            <div className='page-content container image-bg-one' >
                <TechnologiesTitleSubtitle title='REACT NATIVE DEVELOPMENT' subtitle={`Hire Best React Native Programmers\nFor Powerful Apps`}  titleColor="white"  subtitleColor="white"   hrColor="white"/>
                <p className='simple-p-tag' style={{color : 'white'}}>
                    We are always a match for your project specific needs with diverse react Native profiles. Enjoy flexible hiring models,along with teams that ensure your goals and objectives are always underlined while developing apps.
                </p>
            </div>
            <div className='page-content container'>
                <TechnologiesTitleSubtitle title='REACT NATIVE DEVELOPMENT' subtitle={`Hire A React Native Developer In 4 Simple Steps`} />
                <Processstep />
            </div>
            <div className='tech-expertise-main'>
                <TechnologiesTitleSubtitle title='REACT NATIVE DEVELOPMENT' subtitle={`Hire Our Dedicated React Native Developers\nWith Best React Native Skills`} />
                <div className='tech-expertise container'>
                    <DevelopmentSkillCard DevelopmentSkillCardData={DevelopmentSkillCardData} />
                </div>
            </div>
            <div className='page-content container'>
                <TechnologiesTitleSubtitle title='APP DEVELOPMENT COMPANY' subtitle={`Why Choose Technolee For React Native App Development Company?`} />
                <div className='container'>
                    <MySwiperComponent />
                </div>
            </div>
            <div className='page-content container image-bg-two' >
                <TechnologiesTitleSubtitle title='LET START BUILDING YOUR APP!'   subtitle={`Ready To Build The App You Always Imagined?\nHire The Dedicated React Native Developers With Us, And Make Your Dream`} titleColor="white" hrColor="white"  subtitleColor="white" />
                <div className='btn-arr'>
                    <div className='Getstarted-btn' >
                        <Link to="/contactus" style={{width : '280px' , padding : '15px 20px', fontSize: "15px"}} > <span>GET STARTED NOW!</span></Link>
                    </div>
                </div>
            </div>
            <div className='page-content container'>
                <div className='faqs-main'>
                    <TechnologiesTitleSubtitle title='FIND USEFUL' subtitle={`Frequently Asked Questions`} />
                    <FAQs TestimonialsData={TestimonialsData} />
                </div>
            </div>

        </>
    )
}
