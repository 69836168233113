import React from 'react';
import { AiFillInstagram } from "react-icons/ai";
import { FaFacebookF, FaLinkedinIn, FaTwitter } from 'react-icons/fa';
import { MdOutlineMail, MdPhoneInTalk } from 'react-icons/md';
import { FaXTwitter } from "react-icons/fa6";
import { Link } from 'react-router-dom';
import './footer.css';

export default function Footer() {
    return (
        <div>
            <footer className="footer-main">
                <div className='flex-space-between'>
                    <div className="footer__addr">
                        <div className="footer__logo">
                            <img src="https://cdn.technolee.com/image/technoleecolor.webp" 
                                 alt='Technolee' 
                                 loading="lazy" 
                                 srcSet="https://cdn.technolee.com/image/technoleecolor.webp 1x, https://cdn.technolee.com/image/technoleecolor@2x.webp 2x" />
                        </div>
                        <div className="footer-center">
                            <p className='footer-subheader'>Get In Touch</p>
                            <div className='getintouch'>
                                <MdOutlineMail className='sales-icon' />
                                <a href="mailto:hello@technolee.com">hello@technolee.com</a>
                            </div>
                            <div className='getintouch'>
                                <MdPhoneInTalk className='sales-icon' />
                                <a href='tel:+91 95585 79500'>+91 95585 79500</a>
                            </div>

                            <p className='footer-subheader'>Career/HR</p>
                            <div className='getintouch'>
                                <MdOutlineMail className='sales-icon' />
                                <a href="mailto:join@technolee.com">join@technolee.com</a>
                            </div>
                            {/* <div className='getintouch'>
                                <MdPhoneInTalk className='sales-icon' />
                                <a href='tel:+91 98258 02031'>+91 98258 02031</a>
                            </div> */}

                            <p className='footer-subheader'>Follow Us On</p>
                            <div className="footer-icons">
                                <ul className='social-ul'>
                                    <li>
                                        <Link to='https://www.linkedin.com/company/wearetechnolee' aria-label='www.linkedin.com' target="_blank">
                                            <FaLinkedinIn className='social-icon' />
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to='https://facebook.com/wearetechnolee' aria-label='facebook.com' target="_blank">
                                            <FaFacebookF className='social-icon' />
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to='https://www.instagram.com/wearetechnolee/' aria-label='www.instagram.com' target="_blank">
                                            <AiFillInstagram className='social-icon' />
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to='https://twitter.com/wearetechnolee' aria-label='twitter.com' target="_blank">
                                            <FaXTwitter className='social-icon' />
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className='footer-header'>
                        <h2>Global Presence</h2>
                        <div className="footer-visit">
                            <div className='footer-content'>
                                <video loop={true} autoPlay className='footer-flge'>
                                    <source src="https://cdn.technolee.com/image/indiaflage.mp4" type="video/mp4" />
                                    <track src="captions_en.vtt" kind="captions" srclang="en" label="english_captions" />
                                </video>
                                <h2>Bharat (India)</h2>
                            </div>
                            <p className='footer-content-header'>Ahmedabad</p>
                            <p className='footer-visit-address'>
                                A-903 Siddhi Vinayak Towers,<br />
                                Nr. Kataria Arcade, SG Highway<br />
                                Ahmedabad, Gujarat – 380051
                            </p>
                        </div>
                    </div>

                    <div className='footer-link-list'>
                        <ul className="footer__nav">
                            <li className="nav__item">
                                <h2 className="nav__title">Services</h2>
                                <ul className="nav__ul">
                                    <li>
                                        <Link to="/services/digital-transformation">Digital Transformation</Link>
                                    </li>
                                    <li>
                                        <Link to="/services/mobile-apps">Mobile App</Link>
                                    </li>
                                    <li>
                                        <Link to="/services/web-development">Web Development</Link>
                                    </li>
                                    <li>
                                        <Link to="/services/uiux-design">UI/UX Design</Link>
                                    </li>
                                    <li>
                                        <Link to="/services/dedicated-developers">Dedicated Developers</Link>
                                    </li>
                                    <li>
                                        <Link to="/services/qa-service">QA Services</Link>
                                    </li>
                                    <li>
                                        <Link to="/services/product-development">Product Development</Link>
                                    </li>
                                    <li>
                                        <Link to="/services/iot-service">IoT</Link>
                                    </li>
                                </ul>
                            </li>

                            <li className="nav__item nav__item--extra">
                                <h2 className="nav__title">Technologies</h2>
                                <ul className="nav__ul">
                                    <li>
                                        <Link to="/technologies/dotnet">.NET</Link>
                                    </li>
                                    <li>
                                        <Link to="/technologies/reactjs">ReactJS</Link>
                                    </li>
                                    <li>
                                        <Link to="/technologies/reactnative">React Native</Link>
                                    </li>
                                    <li>
                                        <Link to="/technologies/sqlserver">SQL Server</Link>
                                    </li>
                                    <li>
                                        <Link to="/technologies/nodejs">Node</Link>
                                    </li>
                                    <li>
                                        <Link to="/technologies/ios">iOS</Link>
                                    </li>
                                    <li>
                                        <Link to="/technologies/android">Android</Link>
                                    </li>
                                    <li>
                                        <Link to="/technologies/typescript">TypeScript</Link>
                                    </li>
                                </ul>
                            </li>

                            <li className="nav__item">
                                <h2 className="nav__title">Company</h2>
                                <ul className="nav__ul">
                                    <li>
                                        <Link to="/about">About</Link>
                                    </li>
                                    <li>
                                        <Link to="/portfolio">Portfolio</Link>
                                    </li>
                                    <li>
                                        <Link to="/career">Current Openings</Link>
                                    </li>
                                    <li>
                                        <Link to="/career/life">Life @ Technolee</Link>
                                    </li>
                                    <li>
                                        <Link to="/contactus">Contact</Link>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
                <hr className='footer-hr' />

                <div className="legal flex">
                    <div>© {new Date().getFullYear()} <span>TECHNOLEE</span>, All Rights Reserved.</div>
                    <div className='footer-makeindia-logo'>
                        <img src="https://cdn.technolee.com/image/makeinindia.webp" 
                             alt='make india' 
                             loading="lazy" 
                             srcSet="https://cdn.technolee.com/image/makeinindia.webp 1x, https://cdn.technolee.com/image/makeinindia@2x.webp 2x" />
                    </div>
                </div>
            </footer>
        </div>
    )
}
