import React from 'react';
import { Helmet } from 'react-helmet';
import Banner from '../../camponets/Banner/Banner';
import AboutCard from '../../camponets/AboutCard/AboutCard';
import GoogleMap from '../../camponets/GoogleMap/GoogleMap';
import Location from '../../camponets/Location/Location';
import PageSectionMain from '../../camponets/page-Section-Main/PageSectionMain';
import PageSectionSecondary from '../../camponets/Page-Section-Secondary/PageSectionSecondary';
import Testimonials from '../../camponets/Testimonials/Testimonials';



export default function ContactUs() {
  const htmlString = 'Happy To <span class="text-highlight">Help</span> You';

  const TestimonialsData = [
    { alt: "Matt Gaunt", name: "Matt Gaunt", Designation: "Fulfillment Director", imageUrl: "https://cdn.technolee.com/image/avatar/avatar1.webp", description: " <b>Elite BDM</b> has truly revolutionized our business operations.As company representatives, the seamless management of dealers, efficient order processing, and instant access to a wealth of information have become a reality with this avant- garde iPad application.The offline - first approach ensures uninterrupted functionality,setting a new standard for efficiency indealer management.Kudos to the exceptional team at Technolee for delivering a cutting - edge solution that has transformed the way we operate,showcasing their remarkable technical prowess and commitment to excellence" },
    { alt: "Nitesh Joshi", name: "Nitesh Joshi", Designation: "Managing director", imageUrl: "https://cdn.technolee.com/image/avatar/avatar5.webp", description: " <b>EazyMeds</b>, our go-to online pharmacy developed by Technolee, has been a game-changer for our medicine purchases. Whether we're looking for specific medications or uploading prescriptions, <b>EazyMeds</b> ensures a straightforward experience. Tracking orders is a breeze, and we appreciate the platform's design for its convenience in managing our health and wellness journey" },
    { alt: "Matt Gaunt", name: "Matt Gaunt", Designation: "Fulfillment Director", imageUrl: "https://cdn.technolee.com/image/avatar/avatar1.webp", description: " Technolee has reimagined the mobile recharge experience with <b>SIMply</b>, a crossplatform marvel that revolutionizes how dealers engage with their services. This app not only streamlines quick top-ups but also introduces an innovative rewards system, injecting an exciting layer of gamification. With a user-friendly interface meticulously crafted by Technolee, managing users becomes effortlessly seamless. <b>SIMply</b> delivers an immersive and rewarding journey for dealers, showcasing Technolee's ingenuity in creating cutting-edge solutions that elevate the mobile recharge industry to new heights." },
    { alt: "Kaushal Mehta", name: "Kaushal Mehta", Designation: "Managing director", imageUrl: "https://cdn.technolee.com/image/avatar/avatar5.webp", description: " For <b>Chemisto</b>, the B2B mobile app developed by Technolee, it has truly transformed our medical procurement process. The SaaS-based app simplifies the purchase of medicines, providing a seamless and efficient experience. <b>Chemisto</b> goes beyond being just an app; it's a catalyst for transforming B2B interactions in the pharmaceutical domain. Thanks to Technolee, we now have a reliable tool that significantly streamlines our operations in the pharmaceutical sector." },
    { alt: "Matt Gaunt", name: "Matt Gaunt", Designation: "Fulfillment Director", imageUrl: "https://cdn.technolee.com/image/avatar/avatar1.webp", description: " Technolee has developed <b>Rewards</b>, a cross-platform solution tailored for distributors. This practical app provides an immersive merchandising experience, allowing distributors to easily redeem points, make purchases, and navigate transaction details seamlessly. It serves as a testament to Technolee's commitment to bridging the gap between convenience and functionality, offering a unified platform for distributors to enjoy a straightforward and rewarding experience." },
    { alt: "Jignesh Shah", name: "Jignesh Shah", Designation: "Managing director", imageUrl: "https://cdn.technolee.com/image/avatar/avatar5.webp", description: " <b>Erasafe</b>, the FinTech application developed by Technolee, has empowered our financing company to efficiently manage retailers, wallets, redeem points, and handle user interactions seamlessly. This comprehensive app ensures a smooth journey from lead generation to deal completion, creating a seamless experience for financial entities like ours. Thanks to Technolee, we now have a reliable FinTech solution that streamlines our operations, contributing to our overall efficiency and success." },
  ]

  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <meta content="width=device-width, initial-scale=1.0" name="viewport" />

        <title>Contact Technolee - Your Partner in Software & Web Development, Digital Transformation, and Product Innovation</title>
        <meta name="description" content="Reach out to Technolee, a leading software and web development company in Ahmedabad, India. Collaborate with us to drive digital transformation and innovate your products." />
        <meta name="keywords" content="contact us, software development, web development, digital transformation, product development, Ahmedabad, India, Technolee" />
      </Helmet>

      <Banner
        alt={'Contact Us'}
        image="https://cdn.technolee.com/image/contactus/banner.webp"
        title={htmlString}
        description="Your gateway to Software & Web Development, Digital Transformation, and Product Innovation. We're here to turn your digital visions into reality."
      />
      <div className='page-content container'>
        <div>
          {/* <p className='simple-p-tag'>At Technolee, we embarked on our journey in 2017 with a vision to redefine the digital landscape. As a dynamic software and web development company, we have been at the forefront of innovation, driving transformation across various industries. Our commitment to delivering excellence has established us as a trusted partner for businesses seeking to leverage technology for growth and success.</p> */}

        </div>
        <div className='contectus-card'>
          <AboutCard isLeft={false}
            link=""
            image=""
            title="CONTACT US"
            mainTitle="Let's Transform, Create, and Innovate"
            description="At Technolee, we're your trusted partner in the dynamic world of technology. Our expertise spans Software & Web Development, Digital Transformation, and Product Innovation, making us your go-to source for comprehensive solutions."
            description2="Our passionate team in Ahmedabad, India, is eager to connect with you. Whether you have a digital transformation initiative, a unique product concept, or software and web development needs, we have the knowledge and skills to bring your ideas to life.
We specialize in guiding businesses through the process of digital transformation, ensuring that you stay ahead in the ever-evolving digital landscape. Our product development team is dedicated to transforming your innovative ideas into market-ready solutions"
          />
        </div>
      </div>
      <div className='visitus'>
        <PageSectionMain title='OUR LOCATIONS' />
        <PageSectionSecondary title='Visit us on' />
        <div className='flex-space-between visit-content' style={{ padding: '0px 5%' }}>
          <div className='visit-data' style={{ width: '25%' }}>
            <Location />
          </div>
          <div className="map-container" style={{ width: '75%' }}>
            {/* Include your Google Map component here */}
            <GoogleMap address="123 Main St, City, Country" />
          </div>
        </div>
      </div>
      <div className='container testimonials-contectus'>
        <PageSectionMain title='TESTIMONIAL' />
        <PageSectionSecondary title='What Our Clients Say' />
        <Testimonials TestimonialsData={TestimonialsData} />
      </div>
    </>
  )
}
